<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <b-carousel
          id="carousel-interval"
          indicators
          :interval="5000"
        >
          <b-carousel-slide :img-src="require('@/assets/images/banner/test.png')" />
          <b-carousel-slide :img-src="require('@/assets/images/banner/test.png')" />
          <b-carousel-slide :img-src="require('@/assets/images/banner/test.png')" />
        </b-carousel>

        <div class="card card-congratulation-medal mt-2">
          <div class="card-body">
            <h4>Congratulations 🎉 Your Credit!</h4>
            <p class="card-text">
              Total Credit
            </p>
            <h3 class="mb-75 mt-2">
              <a
                href="javascript:void(0);"
                target="_self"
                class=""
              >THB : 0.00</a>
            </h3>
            <img
              src="https://www.pngall.com/wp-content/uploads/4/Dollar-Gold-Coin-PNG.png"
              alt="Medal Pic"
              height="100"
              class="congratulation-medal mt-2"
            >
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="text-center mb-0">
              Your Product 🎉
            </h3>
          </div>

          <div class="card-body">
            <div
              style="height: 596px; overflow-x: hidden;"
            >
              <div class="row mx-1">
                <div class="col-6">
                  Product Name
                </div>

                <div class="col-6">
                  Received Percentage
                </div>
              </div>

              <div
                v-for="item in items"
                :key="item.ID"
                class="row list-items mx-1"
              >
                <div class="col-6">
                  <h5 class="mb-0">
                    {{ item.ID }}
                  </h5>
                </div>

                <div class="col-6">
                  <h5 class="mb-0">
                    {{ agData[item.hold] ? `THB - ${agData[item.hold].toFixed(2)}%` : 'THB - 0.00%' }}
                  </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  name: 'Dashboard',
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      // items: [
      //   { ID: 'AWC', our: 'awc_our', hold: 'awc_hold' },
      //   { ID: 'SBOBET', our: 'sbobet_our', hold: 'sbobet_hold' },
      //   { ID: 'LOTTO', our: 'lotto_our', hold: 'lotto_hold' },
      //   { ID: 'PGSOFT2', our: 'pg_our', hold: 'pg_hold' },
      //   { ID: 'SLOTXO', our: 'slotxo_our', hold: 'slotxo_hold' },
      //   { ID: 'ASKMEBET', our: 'askmebet_our', hold: 'askmebet_hold' },
      //   { ID: 'HABANERO', our: 'habanero_our', hold: 'habanero_hold' },
      //   { ID: 'SPADE', our: 'spade_our', hold: 'spade_hold' },
      //   { ID: 'MICRO', our: 'micro_our', hold: 'micro_hold' },
      //   { ID: 'SIMPLEPLAY', our: 'simple_our', hold: 'simple_hold' },
      //   { ID: 'LIVE22', our: 'live22_our', hold: 'live22_hold' },
      //   // { ID: 'GAMATRON', our: 'gamatron_our', hold: 'gamatron_hold' },
      //   { ID: 'AMBPOKER', our: 'ambpoker_our', hold: 'ambpoker_hold' },
      //   { ID: 'EVOPLAY', our: 'evoplay_our', hold: 'evoplay_hold' },
      //   { ID: 'CQ9', our: 'cq9_our', hold: 'cq9_hold' },
      //   { ID: 'CQ9V2', our: 'cq9_our', hold: 'cq9_hold' },
      //   { ID: 'JILI', our: 'jili_our', hold: 'jili_hold' },
      //   { ID: 'JOKER', our: 'joker123_our', hold: 'joker123_hold' },
      //   { ID: 'MANNA', our: 'mannaplay_our', hold: 'mannaplay_hold' },
      //   { ID: 'NETENT', our: 'netent_our', hold: 'netent_hold' },
      //   // { ID: 'TIGERGAME', our: 'tigergame_our', hold: 'tigergame_hold' },
      //   { ID: 'FUNKY', our: 'funky_our', hold: 'funky_hold' },
      //   { ID: 'YGGDRASIL', our: 'yggdrasil_our', hold: 'yggdrasil_hold' },
      //   { ID: 'UPG', our: 'upg_our', hold: 'upg_hold' },
      //   // { ID: 'GIOCO', our: 'gioco_our', hold: 'gioco_hold' },
      //   { ID: 'AMEBA', our: 'ameba_our', hold: 'ameba_hold' },
      //   { ID: 'AMBSLOT', our: 'ambslot_our', hold: 'ambslot_hold' },
      //   { ID: 'AMBSLOT2', our: 'ambslot_our', hold: 'ambslot_hold' },
      //   { ID: 'NETENT2', our: 'netent2_our', hold: 'netent2_hold' },
      //   // { ID: 'RELAX', our: 'relax_our', hold: 'relax_hold' },
      //   { ID: 'SEXY', our: 'sexy_our', hold: 'sexy_hold' },
      //   { ID: 'SAGAME', our: 'sa_our', hold: 'sa_hold' },
      //   { ID: 'BIGGAME', our: 'biggame_our', hold: 'biggame_hold' },
      //   { ID: 'DREAM2', our: 'dream_our', hold: 'dream_hold' },
      //   { ID: 'WM', our: 'wm_our', hold: 'wm_hold' },
      //   { ID: 'BETGAME', our: 'betgame_our', hold: 'betgame_hold' },
      //   { ID: 'EBET', our: 'ebet_our', hold: 'ebet_hold' },
      //   { ID: 'ALLBET', our: 'allbet_our', hold: 'allbet_hold' },
      //   { ID: 'AGGAME', our: 'aggame_our', hold: 'aggame_hold' },
      //   { ID: 'PRETTY', our: 'pretty_our', hold: 'pretty_hold' },
      //   { ID: 'PRAGMATIC_LIVECASINO', our: 'pragmatic_casino_our', hold: 'pragmatic_casino_hold' },
      //   { ID: 'PRAGMATIC_SLOT', our: 'pragmatic_slot_our', hold: 'pragmatic_slot_hold' },
      //   { ID: 'XTREME', our: 'xtreme_our', hold: 'xtreme_hold' },
      //   { ID: 'KINGMAKER2', our: 'kingmaker_our', hold: 'kingmaker_hold' },
      //   { ID: 'HOTGRAPH', our: 'hotgraph_our', hold: 'hotgraph_hold' },
      // ],
      agData: {},
      items: [],
    }
  },
  mounted() {
    this.GetData()
    this.GetPrefix()
  },
  methods: {
    GetPrefix() {
      this.$http.get('https://reportapi.power100th.com/api/prefix/list')
        .then(res => {
          this.items = res.data
        })
    },
    async GetData() {
      try {
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/auth/getuser')
        this.agData = Res
      } catch (err) {
        console.log(err)
      }
    },
  },
}

</script>

<style>
.congratulation-medal {
    right: -6px !important;
}
.list-items {
    border: 1px solid #d8f3fd;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(121, 198, 235, 0.295);
}
</style>
